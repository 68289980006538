<template>
  <section> 

    <h5>Notify Users</h5>
    <b-row>
      <b-col v-for="(user, index) in eligibleUsersToNotify" :key="index" cols="6">
        <b-form-checkbox  
          v-model="usersToNotify"
          :value="user.email"
        >
          {{ user.first_name }} {{ user.last_name }}
        </b-form-checkbox>
      </b-col>
    </b-row>
    <br>
    <h5>Optional Notes</h5>
    <quill-editor v-model="additionalNotes" :options="editorOptions"></quill-editor>

  </section>
</template>
 
<script>

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import {
  BFormCheckbox, BRow, BCol
} from 'bootstrap-vue'

import { quillEditor } from 'vue-quill-editor'

export default {

  components: {
    quillEditor,
    BRow,
    BCol,
    BFormCheckbox,
  }, 
 
  props: {
  
  },
  data () {
    return {
      usersToNotify: [],
      additionalNotes: null,
      editorOptions: {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            //['bold', 'italic', 'underline', 'strike'],
            ['bold', 'italic', 'underline'],
            //['blockquote', 'code-block'],
            //[{ 'header': 1 }, { 'header': 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            //[{ 'script': 'sub' }, { 'script': 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            //[{ 'direction': 'rtl' }],
            //[{ 'size': ['small', false, 'large', 'huge'] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }]
            //['clean']
            //['link', 'image', 'video']
          ]
        }
      },
    }
  },
  computed: {
    eligibleUsersToNotify () {
      const returnList = []
      for (let i = 0; i < this.$store.state.dlStore.publicationUsers.length; i++) {
        if (this.$store.state.dlStore.publicationUsers[i].status === 'accepted') {
          returnList.push(this.$store.state.dlStore.publicationUsers[i])
        }
      }
      return returnList
    },
  },
  methods: {

  }
}
</script>
