<template>
  <div class="summary_item" >
    <h5 class="text-primary">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <router-link  :to="articleDetailRouterName"><strong :class="titleColor"> <span v-html=" articleTitle"></span>  </strong></router-link>
    </h5>
    <br>
    <b-row> 
      <b-col v-if="article['e_pub_date']" cols="2">{{ article['e_pub_date'] }}</b-col>
      
      <b-col  v-if="article.doi" cols="3">
        <a :href="'https://doi.org/'.concat(article.doi)" target="_blank">{{ article.doi }}</a>
      </b-col>
      <b-col  v-else-if="article.adjunct && article.adjunct.doi" cols="4">
        <strong>DOI (Adjunct Only): </strong> <strong class="text-warning"> {{ article.adjunct.doi }} </strong> 
      </b-col>
      <b-col  v-else cols="4"><strong>DOI: </strong> <strong class="text-danger"> MISSING </strong> </b-col>
      
      <b-col  cols="7">
        <b>{{ article.article_type }}</b>
        <span v-if="article.relatedArticles && article.relatedArticles.length > 0">
          - related to: 
          <span v-for="(item, index) in article.relatedArticles" :key="index">
            <span v-if="index > 0"> , </span> 
            <a :href="doiUrlPrefix.concat(item.doi)" target="_blank">{{ item.raType }}</a>
          </span>
        </span>
      </b-col>

    </b-row>
    <b-row v-if="article['page']"> 
      <b-col cols="4"> <strong>Page: </strong>{{ article['page'] }}</b-col>
    </b-row>

    <b-row v-if="!article.pdf_name"> 
      <b-col cols="4"> <strong class="text-danger"> PDF Missing </strong></b-col>
    </b-row>
 
    <br>
    
    <b-row> 
      <b-col cols="2">
        <strong>Images</strong>&nbsp;&nbsp;
        <span v-if="article.img_names && article.img_names.length > 0"> ({{ article.img_names.length }})&nbsp;</span>
        <span v-else>(0)&nbsp;</span>
        <span v-if="article.text_img_names && article.text_img_names.length > 0"> ICON ({{ article.text_img_names.length }})&nbsp;</span>
        <span v-if="article.small_images_names && article.small_images_names.length > 0"> SM ({{ article.small_images_names.length }})&nbsp;</span>
        <span v-if="article.medium_images_names && article.medium_images_names.length > 0"> MED ({{ article.medium_images_names.length }})&nbsp;</span>
        <span v-if="article.large_images_names && article.large_images_names.length > 0"> LRG ({{ article.large_images_names.length }})&nbsp;</span>
      
      </b-col>
      <b-col cols="3"><strong>Supplemental</strong> ({{ article.supplementary_names && article.supplementary_names.length }})</b-col>
      <b-col v-if="article.hasTables" cols="3"><strong> Has Tables</strong></b-col>
      <b-col v-if="datasetCount(article.supplementary_names) > 0" cols="3"><strong>Datasets</strong> ({{ datasetCount(article.supplementary_names) }})</b-col>
      <b-col v-if="videoCount(article.supplementary_names) > 0" cols="3"><strong>Videos</strong> ({{ videoCount(article.supplementary_names) }})</b-col>

    </b-row>

    <span v-if="xsdErrors.length > 0">
      <br>
      <b-row> 
        <b-col  cols="12" ><strong class="text-danger">XSD Errors</strong></b-col>
      </b-row>
      <b-row v-for="(error, index) in xsdErrors" :key="index"> 
        <b-col  cols="1"></b-col>
        <b-col  cols="4" >{{ error }}</b-col>
      </b-row>
    </span>
    <span v-if="genericSchematronErrors.length > 0">
      <br>
      <b-row> 
        <b-col  cols="12" ><strong class="text-danger">Generic XML Errors</strong></b-col>
      </b-row>
      <b-row v-for="(error, index) in genericSchematronErrors" :key="index"> 
        <b-col cols="4" >{{ error.location }}</b-col>
        <b-col cols="8" >{{ error.message }}</b-col>
      </b-row>
    </span>

    <span v-if="literatumSchematronErrors.length > 0">
      <br>
      <span>
        <b-row> 
          <b-col  cols="12" ><strong class="text-danger">Host Platform XML Errors</strong></b-col>
        </b-row>
        <b-row  v-for="(error, index) in literatumSchematronErrors" :key="index"> 
          <b-col cols="4" >{{ error.location }}</b-col>
          <b-col cols="8" >{{ error.message }}</b-col>
        </b-row>
        <br>
      </span>
    </span>
    <span v-if="literatumSchematronWarnings.length > 0">
      <br>
      <span>
        <b-row> 
          <b-col  cols="12" ><strong class="text-warning">Host Platform XML Warnings</strong></b-col>
        </b-row>
        <b-row v-for="(warning, index) in literatumSchematronWarnings" :key="index"> 
          <b-col cols="4" >{{ warning.location }}</b-col>
          <b-col cols="8" >{{ warning.message }}</b-col>
        </b-row>
      </span>
    </span>
    <br>
    <b-row>
      <b-col cols="2" >
        <b-form-checkbox v-model="show_more" switch>
          More
        </b-form-checkbox>
      </b-col>
    </b-row>

    <span v-if="show_more">
      <b-card>
        <b-row> 
          <b-col cols="3"> 
            <file-operations :format="format" :article="article" folder="xml" :file="article.file_name"/>
          </b-col>
          <b-col v-if="article.pdf_name" cols="3"> 
            <file-operations :format="format" :article="article" folder="pdf" :file="article.pdf_name"/>
          </b-col>
        </b-row>
        <br>
        <span v-if="article.img_names && article.img_names.length > 0">
          <b-row> 
            <b-col> <strong>Images</strong></b-col>
          </b-row>
          <b-row> 
            <b-col v-for="(image_name, index) in article.img_names" :key="index" cols="3">
              <file-operations :format="format" :article="article" folder="img" :file="image_name"/>
            </b-col>  
          </b-row>
          <br>
        </span>
        <span  v-if="article.text_img_names && article.text_img_names.length > 0">
          <b-row> 
            <b-col> <strong>Icons</strong></b-col>
          </b-row>
          <b-row> 
            <b-col v-for="(image_name, index) in article.text_img_names" :key="index" cols="3">
              <file-operations :format="format" :article="article" folder="text_images" :file="image_name"/>
            </b-col> 
          </b-row>
        </span>
        <span v-if="article.small_images_names && article.small_images_names.length > 0">
          <b-row> 
            <b-col> <strong>Small Images</strong></b-col>
          </b-row>
          <b-row> 
            <b-col v-for="(image_name, index) in article.small_images_names" :key="index" cols="3">
              <file-operations :format="format" :article="article" folder="small_images" :file="image_name"/>
            </b-col> 
          </b-row>
          <br>
        </span>
        <span v-if="article.medium_images_names && article.medium_images_names.length > 0">
          <b-row> 
            <b-col> <strong>Medium Images</strong></b-col>
          </b-row>
          <b-row> 
            <b-col v-for="(image_name, index) in article.medium_images_names" :key="index" cols="3">
              <file-operations :format="format" :article="article" folder="medium_images" :file="image_name"/>
            </b-col> 
          </b-row>
          <br>
        </span>
        <span v-if="article.large_images_names && article.large_images_names.length > 0" >
          <b-row> 
            <b-col> <strong>Large Images</strong></b-col>
          </b-row>
          <b-row> 
            <b-col v-for="(image_name, index) in article.large_images_names" :key="index" cols="3">
              <file-operations :format="format" :article="article" folder="large_images" :file="image_name"/>
            </b-col> 
          </b-row>
          <br>
        </span>
        <br>
        <span v-if="article.suppl_manifest">
          <b-row>
            <b-col cols="6"> 
              <strong>Suppl Manifest </strong>
              <file-operations :format="format" :article="article" folder="suppl_manifest" :file="article.suppl_manifest"/>
            </b-col>
          </b-row>
          <br>
        </span>
        <b-row v-if="article.supplementary_names && article.supplementary_names.length > 0"> 
          <b-col> 
            <b-row> <b-col><strong>Supplemental</strong></b-col></b-row>
            <b-row> 
              <b-col v-for="(supp_name, index) in article.supplementary_names" :key="index" cols="3">
                <file-operations :format="format" :article="article" folder="supp" :file="supp_name"/>
              </b-col>
            </b-row>
          </b-col>
        </b-row> 
      </b-card>
    </span>
  </div>
</template>
 
<script>

import {
  BCard, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

import FileOperations from '../detail/FileOperations.vue'

export default {
 
  components: {
    FileOperations,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
  },
 
  props: {
    format: {
      type: String,
      default: null
    },  
    article: {
      type: Object,
      default: null
    },  
  },
  data () {
    return {
      show_more: null,
    }
  },
  computed: {
    doiUrlPrefix () {
      const { host } = window.location
      const prot = host.includes('localhost') ? 'http://' : 'https://'
      return prot.concat(host, '/publication-admin/', this.$route.params.issn, '/doi/')
    },
    articleDetailRouterName () {
      const xmlFileName = this.article.file_name.split('.xml')[0]
      const returnDict = {
        params: { 
          issn: this.$route.params.issn,
          file_name: xmlFileName,
        } 
      } 
      if (this.$route.path.includes('production-admin')) {
        returnDict.name = 'article-production-detail-admin'
        returnDict.params.vol = this.$route.params.vol
        returnDict.issue = this.$route.params.issue
      } else {
        returnDict.name = 'article-publication-detail-admin'
        returnDict.params.vol = this.$route.params.vol
        returnDict.issue = this.$route.params.issue
      }
      return returnDict
    },
    articleTitle () { 
      if (this.article.title.length === 0) {
        return 'TITLE NOT FOUND'
      }
      //will render text as html, therefore sanitize first
      
      return this.$sanitize(this.article.title)
    },
    miscErrors () {
      if (!this.article.errors) {
        return []
      }
      const returnVal = []
      const keys = Object.keys(this.article.errors)
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === 'schematron_validation' || keys[i] === 'xsd_validation') {
          continue
        }
        returnVal.push(this.article.errors[keys[i]])
      }
      return returnVal 
    },
    xsdErrors () {
      if (!this.article.errors) {
        return []
      }
      const returnVal = []
      const keys = Object.keys(this.article.errors)
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === 'xsd_validation') {
          returnVal.push(this.article.errors[keys[i]])
        }
      }
      return returnVal
    },
    genericSchematronErrors () {
      if (!this.article.errors) {
        return []
      }
      const returnVal = []
      const keys = Object.keys(this.article.errors)
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'schematron_validation') {
          continue
        }
        const schErrors = this.article.errors[keys[i]]
        const shKeys = Object.keys(schErrors)
        for (let j = 0; j < shKeys.length; j++) {
          if (shKeys[j] === 'Generic_Rules') {
            const ruleErrors = schErrors[shKeys[j]]
            for (let k = 0; k < ruleErrors.length; k++) {
              for (let m = 0; m < ruleErrors[k].tests.length; m++) {
                const rec = ruleErrors[k].tests[m]
                returnVal.push(rec)
              }
            }
          } 
        }
      }
      return returnVal
    },
    literatumSchematronWarnings () {
      if (!this.article.warnings) {
        return []
      }
      const returnVal = []
      const keys = Object.keys(this.article.warnings)
     
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'schematron_validation') {
          continue
        }
        const schWarnings = this.article.warnings[keys[i]]
        const shKeys = Object.keys(schWarnings)
        for (let j = 0; j < shKeys.length; j++) {
          if (shKeys[j]  === 'Literatum_Rules') {
            const ruleWarnings = schWarnings[shKeys[j]]
            for (let k = 0; k < ruleWarnings.length; k++) {
              for (let m = 0; m < ruleWarnings[k].tests.length; m++) {
                const rec = ruleWarnings[k].tests[m]
                returnVal.push(rec)
              }
            }
          } 
        }
      }
      return returnVal
    },
    literatumSchematronErrors () {
      if (!this.article.errors) {
        return []
      }
      const returnVal = []
      const keys = Object.keys(this.article.errors)
     
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'schematron_validation') {
          continue
        }
        const schErrors = this.article.errors[keys[i]]
        const shKeys = Object.keys(schErrors)
        for (let j = 0; j < shKeys.length; j++) {
          if (shKeys[j]  === 'Literatum_Rules') {
            const ruleErrors = schErrors[shKeys[j]]
            for (let k = 0; k < ruleErrors.length; k++) {
              for (let m = 0; m < ruleErrors[k].tests.length; m++) {
                const rec = ruleErrors[k].tests[m]
                returnVal.push(rec)
              }
            }
          } 
        }
      }
      return returnVal
    },
    hasErrors () {

      if (!this.miscErrors && !this.xsdErrors && !this.literatumSchematronErrors && !this.genericSchematronErrors) {
        return false
      }

      return this.miscErrors.length > 0
             || this.xsdErrors.length > 0
             || this.literatumSchematronErrors.length > 0
             || this.genericSchematronErrors.length > 0
    },
    titleColor () {
      if (this.hasErrors) {
        return 'text-danger'
      }
      let returnVal = 'text-primary'
      if  (!this.article.title || this.article.title.length === 0) {
        returnVal =  'text-danger'
      } else  if (!this.article.pdf_name || this.article.pdf_name.length === 0) {
        returnVal =  'text-danger'
      } else if  (!this.article.doi) {
        if (this.article.adjunct && this.article.adjunct.doi) {  
          returnVal = 'text-warning' 
        } else {
          returnVal =  'text-danger'
        } 
      } else if (this.article.warnings && Object.keys(this.article.warnings).length > 0) {
        returnVal = 'text-warning' 
      }
      return returnVal
    }
  },
  methods: {
    datasetCount (fileNames) {
      if (!fileNames) {
        return 0
      }
      let cnt = 0
      for (let i = 0; i < fileNames.length; i++) {
        if (fileNames[i].includes('.xls')) cnt += 1
        else if (fileNames[i].includes('.csv')) cnt += 1
      }
      return cnt
    },
    videoCount (fileNames) {
      if (!fileNames) {
        return 0
      }
      let cnt = 0
      for (let i = 0; i < fileNames.length; i++) {
        if (fileNames[i].includes('.mov')) cnt += 1
        else if (fileNames[i].includes('.avi')) cnt += 1
        else if (fileNames[i].includes('.mp4')) cnt += 1
        else if (fileNames[i].includes('.wmv')) cnt += 1
      }
      return cnt
    },
    downloadFile (folder, file) {
      this.$emit('DownloadRequested', this.article.doi, folder, file)
    }, 
  }
}
</script>
