<template>
  <div>
    <b-card-actions title="Active Deposits" bg-variant="light" action-collapse>
      <b-list-group>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CheckCircleIcon"
          />
          <span class="ml-1">
            Crossref Datasets
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CheckCircleIcon"
          />
          <span class="ml-1">
            PubMed Central
          </span>
        </b-list-group-item>
      </b-list-group>
    </b-card-actions>
    <b-card-actions  title="Disabled Deposits" bg-variant="light" collapsed action-collapse>
      <b-list-group>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon"
          />
          <span class="ml-1">
            Crossref Articles
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon"
          />
          <span class="ml-1">
            PubMed
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            BenchSci
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            Japan S & T
          </span>
        </b-list-group-item>

        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            Portico
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            OCLC
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            RePEc
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            Yewno
          </span>
        </b-list-group-item>
        <b-list-group-item class="bg-transparent">
          <feather-icon
            class="align-text-bottom"
            icon="CircleIcon" 
          />
          <span class="ml-1">
            Quertle
          </span>
        </b-list-group-item>       
      </b-list-group>
    </b-card-actions>
  </div>
</template>
   
<script>

import {
  BListGroup, BListGroupItem, 
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions, 
    BListGroup, 
    BListGroupItem, 
  },
  props: {
  },
  data() {
    return {

    }
  }, 
  computed: {
  },
  watch: {

  }, 
  methods: { 
 
  },
}
</script>
