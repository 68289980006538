<template>
  <div>
    <br>
    <!--
    <b-card v-if="$route.params.issn != '1091-6490' &  $route.params.issn != 'PNAS-HIWR'" bg-variant="warning">  
      <h5 class="text-white">Attention</h5>
      <p class="text-white">ISSN: {{ $route.params.issn }} is not supported on Crossref.</p>
    </b-card>
    -->
    <span>
      <b-card v-if="articleList" title="Articles in Crossref">
        <b-table
          :items="articleList"
          :fields="['operations', 'doi', 'title', 'epub', 'deposited']"
          striped 
          responsive="sm" 
        >
          <template #cell(title)="data">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <router-link  v-if="!data.item.depositError" :to="articleDetailRouterName(data.item)" :title="articleFullTitle(data.item)"><b :class="titleColor(data.item)"> <span v-html="articleTitle(data.item)"></span></b></router-link>
            <b v-else class="text-danger"> {{ data.item.depositError }} </b>
          </template>
          <template #cell(doi)="data">
            {{ displayDoiSuff(data.value) }}
          </template>
          <template #cell(operations)="data">
            <b-dropdown
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
            >
              <b-dropdown-item v-if="(inProduction)"> 
                <span class="mr-1">
                  <feather-icon
                    icon="FilePlusIcon"
                    size="16"
                  />
                </span>
                <a class="text-primary" @click.stop="getCrossrefDepositDownloadLink(data.item.doi, 'y')">Create Crossref XML</a>  
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card> 
    </span> 
  </div>
</template>
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'

import {
  BTable,  BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
 
  components: {
    BTable,
    BCard,
    BDropdown, 
    BDropdownItem,
  },
  directives: {
    Ripple,
  },

  props: {
    articles: {
      type: Array,
      default: null
    },     
  },

  data () {
    return {
      articleList: [],
    }
  },
  computed: { 
    inProduction () {
      return this.$route.path.includes('production-admin')
    },
  },
  watch: {
  },

  mounted () {
    this.articleList = []
    this.getCrossrefArticles()
  },
  methods: {
    displayDoiSuff(doi) {
      const nodes = doi.split('/')
      return doi.split(nodes[0].concat('/'))[1]
    },
    articleDetailRouterName (article) {
      const xmlFileName = article.file_name.split('.xml')[0]
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          issue: this.$route.params.issue,
          file_name: xmlFileName,
        }
      } 
      returnDict.params.vol = this.$route.params.vol
      returnDict.name = 'article-publication-detail-admin'
      return returnDict
    },
    articleTitle (article) {
      let title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      if (title.length > 40) {
        title = title.substring(0, 39).concat('...')
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    articleFullTitle (article) {
      const title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    titleColor (article) {
      let returnVal = 'text-primary'
      if (Object.keys(article.errors).length > 0) {
        returnVal = 'text-danger'
      } else if  (Object.keys(article.warnings).length > 0) {
        returnVal = 'text-warning' 
      }
      return returnVal
    },
    getCrossrefArticles () {
      publicationApi.getCrossrefArticles(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, this, null)     
    },

    backFromGetCrossrefArticles (serverData) {
      console.log('bbbbbbb backFromGetCrossrefArticles', serverData)
      if (serverData.error) {
        console.log(serverData.error)
        return
      }
      let results = []
      for (let i = 0; i < serverData.length; i++) {
        results = results.concat(serverData[i].articles)
      }

      //console.log(results)
      const crDict = {}
      for (let i = 0; i < results.length; i++) {
        const crArticle = results[i]
        crDict[crArticle.doi] = crArticle

      }

      //match pubmill articles to those found on crossref
      const articleDict = {}
      const returnList = []
      for (let i = 0; i < this.articles.length; i++) {
        //cloning here so as not to change the state of original article property passed in
        const article = JSON.parse(JSON.stringify(this.articles[i]))
        articleDict[article.doi] = article
    
        let epub = '???'
        let deposited = '???'

        if (crDict[article.doi]) {
          epub = crDict[article.doi].epub
          deposited = crDict[article.doi].deposited
        }
        
        returnList.push({ 
          doi: article.doi, 
          title: article.title,
          file_name: article.file_name,
          errors: article.errors,
          warnings: article.warnings,
          epub,
          deposited,
        })
      }

      //check for crossref articles not found in pubmill (only do this in arhive view)
      if (!this.$route.path.includes('production-admin')) {
        for (let i = 0; i < results.length; i++) {
          const crArticle = results[i]
          if (!articleDict[crArticle.doi]) {
            returnList.push({ 
              depositError: 'Crossref DOI not found in system',
              doi: crArticle.doi, 
              epub: crArticle.epub,
              deposited: crArticle.deposited,
            })
          }
        }
      }
      this.articleList =  returnList
    },
    getCrossrefDepositDownloadLink (doi, buildNew) {

      const queryParams = {
        issn: this.$route.params.issn,
        doi,
        buildNew: this.buildNew
      } 
      if (buildNew === 'y') {
        queryParams.vol  = this.$route.params.vol
        queryParams.iss = this.$route.params.issue
      }
      fileMgrApi.getCrossrefDepositDownloadLink(queryParams, this, null)
    },

    backFromGetCrossrefDepositDownloadLink (serverData) {
      //console.log('bbbbbbb', serverData)
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        document.body.appendChild(fileLink)
        fileLink.click() 
      } 
    },
  },
}
</script>
