<template>
  <div>
    <span> 
      <b-row style="margin-left:1rem;">
        <b-col cols="9"/>
        <b-col cols="3">
          <span v-if="!showMenu"></span>
          <b-dropdown  
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Operations"
            variant="primary"
            size="sm"
          >
            <b-dropdown-item 
              @click.stop="promptForReopenInProduction()"
            >
              <nobr>
                <span class="mr-1">Reopen In Production</span>
                <feather-icon
                  size="20"
                  icon="BookIcon"
                />  
              </nobr>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="showSendToDistroMenuItem"
              @click.stop="promptForSendToDistributions()"
            >
              <nobr>
                <span class="mr-1">Send To Distributions</span>
                <feather-icon
                  size="20"
                  icon="ExternalLinkIcon"
                />  
              </nobr>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
      <br>
      <b-card title="Issue Files">
        <b-row>
          <b-col v-for="(issueFile,index) in issueFileList" :key="index" cols="6">
            <nobr>
              <b-form-checkbox
                v-model="selectedIssueFiles"
                :value="issueFile"
                class="float-left"
              />
              {{ issueFile.file }} 
            </nobr>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Articles">
        <b-table
          :items="articleList"
          :fields="['selectAllDOIs', 'doi', 'title']"
          striped
          responsive="sm"
        >
          <template #head(selectAllDOIs)>
            <b-form-checkbox
              v-model="selectAllDOIsChecked"
            />
          </template>
          <template #cell(title)="data">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <router-link :to="articleDetailRouterName(data.item)" :title="articleFullTitle(data.item)"><b :class="titleColor(data.item)"> <span v-html="articleTitle(data.item)"></span></b></router-link>
          </template>
          <template #cell(doi)="data">
            {{ displayDoiSuff(data.value) }}
          </template>
          <template #cell(selectAllDOIs)="data">
            <b-form-checkbox
              v-model="selectedDOIs"
              :value="data.item"
            />
          </template>
        </b-table>
      </b-card>
    </span>
    <!-- Modal Templates below-->
    <b-modal
      id="prompt-for-reopen-in-production" 
      centered
      title="Reopen Selected in Production" 
      ok-title="Accept"
      cancel-title="Cancel"
      size="lg"
      @ok="reopenInProduction"
    >
      <p>Performing this operation will reopen all selected files in this issue into production. Are you sure you want to proceed?</p>
      <br>
      <notes-and-notify ref="notesAndNotify" />
    </b-modal>
    <b-modal
      id="prompt-for-send-to-distributions" 
      centered
      title="Send to selected distributions" 
      ok-title="Send"
      cancel-title="Cancel"
      size="lg"
      @ok="sendToDistributions"
    >
      <span v-for="(distro,index) in distros" :key="index" >
        <b-form-checkbox
          v-model="selectedDistros"
          :value="distro.name"
        >
          {{ distro.title }}
        </b-form-checkbox>
        <br>
      </span>
    </b-modal>
  </div>
</template>
 
<script>

import publicationApi from '@/dl_pubmill/apis/publication'

import {
  BFormCheckbox, BTable, BDropdown, BDropdownItem, BRow, BCol, BCard
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import NotesAndNotify from './NotesAndNotify.vue'

export default {
 
  components: {
    BFormCheckbox,
    BTable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    NotesAndNotify,
  },
  directives: {
    Ripple,
  },
 
  props: {
    articles: {
      type: Array,
      default: null
    },
    issueFiles: {
      type: Array,
      default: null
    },      
  },

  data () {
    return {
      articleList: [],
      selectAllDOIsChecked: false,
      selectedDOIs: [],
  
      issueFileList: [],
      selectedIssueFiles: [],
      distros: null,
      selectedDistros: [],
    }
  },
  computed: { 

    showMenu () {
      return ((this.selectedDOIs && this.selectedDOIs.length > 0) || (this.selectedIssueFiles && this.selectedIssueFiles.length > 0))
    }, 
    showSendToDistroMenuItem () {
      return (!this.selectedIssueFiles  || this.selectedIssueFiles.length === 0)
    }, 
  },
  watch: {
    selectAllDOIsChecked () {
      this.selectedDOIs = []
      if (this.selectAllDOIsChecked === false) {
        return
      }
      for (let i = 0; i < this.articleList.length; i++) {
        this.selectedDOIs.push(this.articleList[i])
      }
    },
  },

  mounted () {
    this.articleList = []
    this.issueFileList = []
    this.getPublication()
  },
  methods: {
    articleDetailRouterName (article) {
      const xmlFileName = article.file_name.split('.xml')[0]
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          issue: this.$route.params.issue,
          file_name: xmlFileName,
        }
      } 
      returnDict.params.vol = this.$route.params.vol
      returnDict.name = 'article-publication-detail-admin'
      return returnDict
    },
    articleTitle (article) {
      let title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      if (title.length > 45) {
        title = title.substring(0, 44).concat('...')
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    articleFullTitle (article) {
      const title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    titleColor (article) {
      if (Object.keys(article.errors).length > 0) {
        return 'text-danger'
      }
      let returnVal = 'text-primary'
      if  (!article.title || article.title.length === 0  || !article.pdf_name || article.pdf_name.length === 0) {
        returnVal =  'text-danger'
      } else if  (!article.doi) {
        if (article.adjunct && article.adjunct.doi) {  
          returnVal = 'text-warning' 
        } else {
          returnVal =  'text-danger'
        } 
      } else if  (Object.keys(article.warnings).length > 0) {
        returnVal = 'text-warning' 
      }
      return returnVal
    },
    refreshCurationList () {

      this.issueFileList = []
      for (let i = 0; i < this.issueFiles.length; i++) {
        const issueFile = { file: this.issueFiles[i] }
        this.issueFileList.push(issueFile)
      }
      
      const returnList = []
      for (let i = 0; i < this.articles.length; i++) {
        //cloning here so as not to change the state of origanl article property passed in
        const article = JSON.parse(JSON.stringify(this.articles[i]))
        returnList.push(article)
      }
      this.articleList =  returnList
    },
    displayDoiSuff(doi) {
      const nodes = doi.split('/')
      return doi.split(nodes[0].concat('/'))[1]
    },
    getPublication() {
      this.distros = []
      this.selectedDistros = []
      publicationApi.getPublication(this.$route.params.issn, this, null)
    },
    backFromGetPublication(serverData) {
      //console.log('33333333333333333333', serverData)
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        this.refreshCurationList()
        for (let i = 0; i < serverData.distros.length; i++) {
          const distro = serverData.distros[i]
          if (distro.active === 'y') {
            this.distros.push(distro)
            this.selectedDistros.push(distro.name)
          }
        }
      }
    },

    promptForReopenInProduction () {

      //.$bvModal.show(.. will reveal template
      this.$bvModal.show('prompt-for-reopen-in-production')

      //this.$bvModal.msgBoxOk(message,..
      //this.$bvModal.msgBox...  is a convenience where a separate template is not required.
      //commented out here for reference in other solutions that do not require a template
      /*
      const message = 'Performing this operation will reopen all selected files in this issue into production. Are you sure you want to proceed?'
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Reopen Selected in Production',
          size: 'sm',
          okVariant: 'warning',
          modalClass: 'modal-warning',
          okTitle: 'Accept',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.reopenInProduction()
          }
        })
      */
    },
    reopenInProduction () {

      let additionalNotes = this.$refs.notesAndNotify.additionalNotes
      if (additionalNotes !== null) {
        console.log('additionalNotes', additionalNotes)
        const tmp = additionalNotes.trim().replace(/\s+/g, '')
        if (tmp === '' || tmp === '<p></p>') {
          additionalNotes = null
        } 
      }

      //console.log('additionalNotes final ', additionalNotes)
      //console.log('uuuu', this.$refs.notesAndNotify.usersToNotify)
      const notesAndNotification = {
        additionalNotes,
        usersToNotify: this.$refs.notesAndNotify.usersToNotify
      }

      const dois = []
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        dois.push(this.selectedDOIs[i].doi)
      }
      const issueFileNames = []
      for (let i = 0; i < this.selectedIssueFiles.length; i++) {
        issueFileNames.push(this.selectedIssueFiles[i].file)
      }
      publicationApi.reopenInProduction(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, dois, issueFileNames, notesAndNotification, this, null)
    },
    backFromReopenInProduction (serverData) {
      if (serverData.error) {
        console.log('backFromFeopenInProduction with error', serverData.error)
        const message = 'A problem occured during the intitial setup of the tranmission.  Please contact your administrator.'
        this.showProblemWithOperation(message)
      } else {
        this.promptToRedirectToProduction()
      } 
    }, 
  
    promptToRedirectToProduction () {

      const message = 'Operation has commenced for your selections. You will now be redirected to the production tracking.  Note that it may take some time for all files to be copied over.'
      //this.$bvModal.msgBoxConfirm(message,..
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Operation in Progress',
          size: 'sm',
          okVariant: 'primary',
          modalClass: 'modal-primary',
          okTitle: 'Ok',
        })
        .then(value => {
          if (value) {
            this.$router.push({ name: 'production-admin', params: { issn: this.$route.params.issn } })
          }
        })
    },

    promptToConfirmDistributionsSent () {

      const message = 'Distributions sent for your selections'
      //this.$bvModal.msgBoxConfirm(message,..
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Distributions Sent',
          size: 'sm',
          okVariant: 'primary',
          modalClass: 'modal-primary',
          okTitle: 'Ok',
        })
    },
    promptToConfirmCrossrefSent () {

      const message = 'Crossref submitted for your selections'
      //this.$bvModal.msgBoxConfirm(message,..
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Crossref Submitted',
          size: 'sm',
          okVariant: 'primary',
          modalClass: 'modal-primary',
          okTitle: 'Ok',
        })
    },

    showProblemWithOperation (message) {
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Problem with Transmission',
          size: 'sm',
          okVariant: 'danger',
          modalClass: 'modal-danger',
          okTitle: 'Ok',
        })
    },
    promptForSendToDistributions () {

      //.$bvModal.show(.. will reveal template
      this.$bvModal.show('prompt-for-send-to-distributions')

    },
    sendToDistributions () {
      const dois = []
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        dois.push(this.selectedDOIs[i].doi)
      }
      if (this.selectedDistros.length === 0) {
        this.showProblemWithOperation('Please select one or more distributions')
        return
      }
      publicationApi.sendToDistributions(this.$route.params.issn, 'n', this.$route.params.vol, this.$route.params.issue, dois, this.selectedDistros, this, null)
    },
    backFromSendToDistributions (serverData) {
      if (serverData.error) {
        console.log('backFromSendToDistributions with error', serverData.error)
        const message = 'A problem occured during the intitial setup of the tranmission.  Please contact your administrator.'
        this.showProblemWithOperation(message)
      } else {
        this.promptToConfirmDistributionsSent()
      } 
    }, 
  },
}
</script>
