<template>
  <div>
    <h3 style="margin-left:2rem;">Filters</h3>
    <b-card bg-variant="light">
      <article-filters-sidebar
        @ArticleFilteringRequested="applyArticleFiltering"
      />
    </b-card>
  </div>
</template>

<script>

import {
  BCard, 
} from 'bootstrap-vue'

import ArticleFiltersSidebar from './ArticleFiltersSidebar.vue'

export default {
  components: {
    BCard,
    ArticleFiltersSidebar,
  },
  props: {
  },
  data() {
    return {
    }
  }, 
  computed: {
  },
  watch: { 
  }, 
  methods: { 
    applyArticleFiltering (showValid, showErrors, showWarnings, showResearchArticles) { 
      this.$emit('ArticleFilteringRequested', showValid, showErrors, showWarnings, showResearchArticles)
    }, 
  },
}
</script>
