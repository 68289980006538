import { render, staticRenderFns } from "./PublishedIssueSidebar.vue?vue&type=template&id=2fae4269&"
import script from "./PublishedIssueSidebar.vue?vue&type=script&lang=js&"
export * from "./PublishedIssueSidebar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports