<template>
  <div>
    <span> 
      <b-row style="margin-left:1rem;">
        <b-col cols="6">
          <span v-if="!showMenu"></span>
          <b-dropdown  
            v-else
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            text="Operations"
            variant="primary"
            size="sm"
          >
            <b-dropdown-item 
              v-if="selectedItemsCanBeStaged  && FORMAT  != 'PMC'"
              @click.stop="promptForStageOnWebHost()"
            >
              <nobr>
                <span class="mr-1">Stage selected on Web Host</span>
                <feather-icon
                  size="20"
                  icon="ExternalLinkIcon"
                />  
              </nobr>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="selectedItemsCanBeStaged"
              @click.stop="promptForPackageStaging()"
            >
              <nobr>
                <span class="mr-1">Export a staging bundle</span>
                <feather-icon
                  size="20"
                  icon="DownloadCloudIcon"
                />  
              </nobr>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="selectedItemsCanBeMarkedAsPublishedWithoutStaging"
              @click.stop="promptForMarkAsPublished()"
            >
              <nobr>
                <span v-if="FORMAT  == 'PMC'" class="mr-1">Moved to Published</span>
                <span v-else class="mr-1">Move selected back to published without staging</span>
                <feather-icon
                  size="20"
                  icon="BookIcon"
                />  
              </nobr>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="selectedItemsCanBeMarkedAsPublished"
              @click.stop="promptForMarkAsPublished()"
            >
              <nobr>
                <span class="mr-1">Mark selected as published</span>
                <feather-icon
                  size="20"
                  icon="BookIcon"
                />  
              </nobr>
            </b-dropdown-item>
            <b-dropdown-item 
              v-if="stageItemsInTransit > 0"
              @click.stop="getStagedOnWebHost()"
            >
              <nobr>
                <span class="mr-1">Check Status on In Transit items</span>
                <feather-icon
                  size="20"
                  icon="RefreshCwIcon"
                />  
              </nobr>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="6" align="right">
          <span class="pill_gap">Unstaged</span>         
          <b-badge pill variant="light-secondary">{{ unstagedItems }}</b-badge>
          <span class="pill_gap"> , </span>  
          <span class="pill_gap">In Staging</span>         
          <b-badge pill variant="light-secondary">{{ stageItemsInStaging }}</b-badge>
          <span> , </span>  
          <span class="pill_gap">In Transit</span>         
          <b-badge pill variant="light-secondary">{{ stageItemsInTransit }}</b-badge>
        </b-col> 
      </b-row>
      <br>
      <b-card title="Issue Files">
        <b-row>
          <b-col v-for="(issueFile,index) in issueFileList" :key="index" cols="6">
            <nobr>
              <b-form-checkbox
                v-model="selectedIssueFiles"
                :value="issueFile"
                class="float-left"
              />
              {{ issueFile.file }} 
              <span v-if="issueFile.staged == 'in transit'" class="text-success"> ({{ issueFile.staged }})</span>
              <span v-if="issueFile.staged == 'in staging'" class="text-info">({{ issueFile.staged }})</span>
            </nobr>
          </b-col>
        </b-row>
      </b-card>
      <b-card title="Articles">
        <b-table
          :items="articleList"
          :fields="['selectAllDOIs', 'staged', 'ePub', 'title', 'doi']"
          striped
          responsive="sm"
        >
          <template #head(selectAllDOIs)>
            <b-form-checkbox
              v-model="selectAllDOIsChecked"
            />
          </template>
          <template #cell(selectAllDOIs)="data">
            <b-form-checkbox
              v-model="selectedDOIs"
              :value="data.item"
            />
          </template>
          <template #cell(staged)="data">
            <span v-if="data.value == 'in transit'" class="text-success"><nobr>{{ data.value }}</nobr></span>
            <span v-if="data.value == 'in staging'" class="text-info"><nobr>{{ data.value }}</nobr></span>
          </template>
          <template #cell(ePub)="data">
            <nobr>{{ data.item.e_pub_date }}</nobr>
          </template>
          <template #cell(title)="data">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <router-link :to="articleDetailRouterName(data.item)" :title="articleFullTitle(data.item)"><b :class="titleColor(data.item)"> <span v-html="articleTitle(data.item)"></span></b></router-link>
          </template>
          <template #cell(doi)="data">
            {{ articleDisplayDoi(data.item.doi) }}
          </template>

        </b-table>
      </b-card>
    </span>
    <!-- Modal Templates below-->
    <b-modal
      id="prompt-for-mark-as-published" 
      centered
      title="Mark Selected as Published" 
      ok-title="Accept"
      cancel-title="Cancel"
      size="lg"
      @ok="moveToPublished"
    >
      
      <p v-if="FORMAT == 'PMC'">Performing this operation will promote all selected files to 'published' and move them to the issue archive. Are you sure you want to proceed?</p>
      <p v-else>Performing this operation will move all selected files out of production and into the issue archive. Are you sure you want to proceed?</p>
      
      <br>
      <notes-and-notify ref="notesAndNotify" />
    </b-modal>
    <b-modal
      id="prompt-for-stage-on-webhost" 
      centered
      title="Stage on Web Host" 
      ok-title="Accept"
      cancel-title="Cancel"
      size="lg"
      @ok="stageOnWebHost"
    >
      <p>Performing this operation will stage all selected files in this issue onto the Web Host\'s server . Are you sure you want to proceed?</p>
      <br>
      <notes-and-notify ref="notesAndNotify" />
    </b-modal>
    <b-modal
      id="prompt-for-package-staging" 
      centered
      title="Stage on Web Host" 
      ok-title="Accept"
      cancel-title="Cancel"
      size="lg"
      @ok="packageStaging"
    >
      <p>Performing this operation will create a downloadable zip file from the selected resources. Are you sure you want to proceed?</p>
      <br>
 
    </b-modal>
  </div>
</template>
 
<script>

import { $themeConfig } from '@themeConfig'
import publicationApi from '@/dl_pubmill/apis/publication'

import {
  BFormCheckbox, BTable, BDropdown, BDropdownItem, BRow, BCol, BBadge, BCard
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import NotesAndNotify from '../issue/NotesAndNotify.vue'

export default {
 
  components: {
    BFormCheckbox,
    BTable,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BCard,
    NotesAndNotify,
  },
  directives: {
    Ripple,
  },
 
  props: {
    articles: {
      type: Array,
      default: null
    },
    issueFiles: {
      type: Array,
      default: null
    },
    showUnstaged: {
      type: Boolean,
      default: true
    },
    showInTransit: {
      type: Boolean,
      default: true
    }, 
    showInStaging: {
      type: Boolean,
      default: true
    },      
  },

  data () {
    return {
      articleList: [],
      selectAllDOIsChecked: false,
      selectedDOIs: [],
      stagedDois: null,

      issueFileList: [],
      selectedIssueFiles: [],
      stagedIssueFiles: null, 
      FORMAT: null,
    }
  },
  computed: {
    showMenu () {
      return this.selectedItemsCanBeStaged || this.selectedItemsCanBeStaged || this.stageItemsInTransit > 0
    }, 
    selectedItemsCanBeStaged () {
      if ((!this.selectedDOIs || this.selectedDOIs.length === 0) && (!this.selectedIssueFiles || this.selectedIssueFiles.length === 0)) {
        return false
      }
      return true
    },     
    selectedItemsCanBeMarkedAsPublished () {
      if ((!this.selectedDOIs || this.selectedDOIs.length === 0) && (!this.selectedIssueFiles || this.selectedIssueFiles.length === 0)) {
        return false
      }
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        
        if (this.selectedDOIs[i].staged !== 'in staging') {
          return false
        }
      }
      for (let i = 0; i < this.selectedIssueFiles.length; i++) {
        if (this.selectedIssueFiles[i].staged !== 'in staging') {
          return false
        }
      }
      return true
    }, 
    selectedItemsCanBeMarkedAsPublishedWithoutStaging () {
      if ((!this.selectedDOIs || this.selectedDOIs.length === 0) && (!this.selectedIssueFiles || this.selectedIssueFiles.length === 0)) {
        return false
      }
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        if (this.selectedDOIs[i].staged) {
          return false
        }
      }
      for (let i = 0; i < this.selectedIssueFiles.length; i++) {
        if (this.selectedIssueFiles[i].staged) {
          return false
        }
      }
      return true
    }, 
    stageItemsInTransit () {
      let cnt = 0
      for (let i = 0; i < this.articleList.length; i++) {
        if (this.articleList[i].staged === 'in transit') {
          cnt += 1
        }
      }
      for (let i = 0; i < this.issueFileList.length; i++) {
        if (this.issueFileList[i].staged === 'in transit') {
          cnt += 1
        }
      }
      return cnt
    }, 
    stageItemsInStaging () {
      let cnt = 0
      for (let i = 0; i < this.articleList.length; i++) {
        if (this.articleList[i].staged === 'in staging') {
          cnt += 1
        }
      }
      for (let i = 0; i < this.issueFileList.length; i++) {
        if (this.issueFileList[i].staged === 'in staging') {
          cnt += 1
        }
      }
      return cnt
    }, 
    unstagedItems () {
      let cnt = 0
      if (!this.articleList || this.articleList.length === 0) {
        return 0
      }

      for (let i = 0; i < this.articleList.length; i++) {
        if (!this.articleList[i].staged) {
          cnt += 1
        }
      }
      return cnt
    }, 
  },
  watch: {
    selectAllDOIsChecked () {
      this.selectedDOIs = []
      if (this.selectAllDOIsChecked === false) {
        return
      }
      for (let i = 0; i < this.articleList.length; i++) {
        this.selectedDOIs.push(this.articleList[i])
      }
    },
    showUnstaged () {
      // console.log('showUnstaged changed', this.showUnstaged)
      this.refreshCurationList()
    },
    showInTransit () {
      // console.log('showInTransit changed', this.showInTransit)
      this.refreshCurationList()
    },
    showInStaging () {
      // console.log('showInStaging changed', this.showInStaging)
      this.refreshCurationList()
    },
  },

  mounted () {
    this.FORMAT =  $themeConfig.app.envVars.FORMAT
    this.articleList = []
    this.issueFileList = []
    this.getStagedOnWebHost()
  },
  methods: {
    articleDetailRouterName (article) {
      const xmlFileName = article.file_name.split('.xml')[0]
      const returnDict = {
        params: {
          issn: this.$route.params.issn,
          issue: this.$route.params.issue,
          file_name: xmlFileName,
        }
      } 
      returnDict.params.vol = this.$route.params.vol
      returnDict.name = 'article-production-detail-admin'
      return returnDict
    },
    articleTitle (article) {
      let title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      if (title.length > 30) {
        title = title.substring(0, 29).concat('...')
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    articleDisplayDoi (doi) {
      if (doi.includes('/')) {
        const nodes = doi.split('/')
        return doi.split(nodes[0].concat('/'))[1]
      }
      return doi
      
    },
    articleFullTitle (article) {
      const title = article.title
      if (title.length === 0) {
        return 'TITLE NOT FOUND'
      } 
      //will render text as html, therefore sanitize first
      //console.log('aaaaaaav', this.$sanitize(title))
      return this.$sanitize(title)

    },
    titleColor (article) {
      if (Object.keys(article.errors).length > 0) {
        return 'text-danger'
      }
      let returnVal = 'text-primary'
      if  (!article.title || article.title.length === 0  || !article.pdf_name || article.pdf_name.length === 0) {
        returnVal =  'text-danger'
      } else if  (!article.doi) {
        if (article.adjunct && article.adjunct.doi) {  
          returnVal = 'text-warning' 
        } else {
          returnVal =  'text-danger'
        } 
      } else if  (Object.keys(article.warnings).length > 0) {
        returnVal = 'text-warning' 
      }
      return returnVal
    },
    refreshCurationList () {

      this.issueFileList = []
      for (let i = 0; i < this.issueFiles.length; i++) {
        const issueFile = { file: this.issueFiles[i] }
        if (this.stagedIssueFiles[issueFile.file]) {
          //console.log('iii', issueFile, 'ff', this.stagedIssueFiles[issueFile])
          issueFile.staged = this.stagedIssueFiles[issueFile.file]
        }
        this.issueFileList.push(issueFile)
      }
      
      const returnList = []
      for (let i = 0; i < this.articles.length; i++) {
        //cloning here so as not to change the state of origanl article property passed in
        const article = JSON.parse(JSON.stringify(this.articles[i]))
        const nodes = article.doi.split('/')
        const doiSuff = article.doi.split(nodes[0].concat('/'))[1]
        //console.log('stagedDois', this.stagedDois)
        if (this.stagedDois[doiSuff]) {
          //console.log('ddd', doiSuff, 'ff', this.stagedDois[doiSuff])
          article.staged = this.stagedDois[doiSuff]
        }
        //console.log('article stg', article.staged)
        if (this.showUnstaged && !(article.staged === 'in transit' || article.staged  === 'in staging')) {
          returnList.push(article)
        } else if (this.showInTransit && article.staged === 'in transit') {
          returnList.push(article)
        } else if (this.showInStaging && article.staged  === 'in staging') {
          returnList.push(article)
        } 
      }
      this.articleList =  returnList
    },
    getStagedOnWebHost () {
      publicationApi.getStagedOnWebHost(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, this, null)
    },
    backFromGetStagedOnWebHost (serverData) {
      this.selectedDOIs = []
      this.selectedIssueFiles = []
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('sssss', serverData)
        this.stagedIssueFiles = {}
        this.stagedDois = {}
        for (let i = 0; i < serverData.length; i++) {
          const status = serverData[i].status
          const type = serverData[i].type
          const stageId = serverData[i].stage_id
          if (type === 'issue file') {
            //console.log('asssssssssss', stageId)
            this.stagedIssueFiles[stageId] = status
          } else {
            this.stagedDois[stageId] = status
          }
        }
        this.refreshCurationList()
      } 
    },

    promptForPackageStaging () {
      this.$bvModal.show('prompt-for-package-staging')
    },

    promptForStageOnWebHost () {
      //.$bvModal.show(.. will reveal template
      this.$bvModal.show('prompt-for-stage-on-webhost')
      
      //this.$bvModal.msgBox...  is a convenience where a separate template is not required.
      //commented out here for reference in other solutions that do not require a template
      //this.$bvModal.msgBoxOk(message,..
      /* 
      const message = 'Performing this operation will stage all selected files in this issue onto the Web Host\'s server . Are you sure you want to proceed?'
      //this.$bvModal.msgBoxOk(message,..
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Stage on Web Host',
          size: 'sm',
          okVariant: 'warning',
          modalClass: 'modal-warning',
          okTitle: 'Accept',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.stageOnWebHost()
          }
        })
        */
    },
    packageStaging () {

      const dois = []
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        dois.push(this.selectedDOIs[i].doi)
      }
      const issueFileNames = []
      for (let i = 0; i < this.selectedIssueFiles.length; i++) {
        issueFileNames.push(this.selectedIssueFiles[i].file)
      }
      //console.log('staging ddd issueFileNames', issueFileNames, 'dois', dois)
      publicationApi.packageStaging(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, dois, issueFileNames, this, null)
    },
    backFromPackageStaging (serverData) {
      this.selectedDOIs = []
      this.selectedIssueFiles = []
      if (serverData.error) {
        console.log('backFromPackageStaging with error', serverData.error)
        const message = 'A problem occured during the intitial setup of the tranmission.  Please contact your administrator.'
        this.showProblemWithStagingPreparation(message)
      } else {
        this.$router.push({ name: 'reports', params: { issn: this.$route.params.issn } })
      } 
    }, 
    stageOnWebHost () {

      let additionalNotes = this.$refs.notesAndNotify.additionalNotes
      if (additionalNotes !== null) {
        console.log('additionalNotes', additionalNotes)
        const tmp = additionalNotes.trim().replace(/\s+/g, '')
        if (tmp === '' || tmp === '<p></p>') {
          additionalNotes = null
        } 
      }
    
      //console.log('additionalNotes final ', additionalNotes)
      //console.log('uuuu', this.$refs.notesAndNotify.usersToNotify)
      const notesAndNotification = {
        additionalNotes,
        usersToNotify: this.$refs.notesAndNotify.usersToNotify
      }

      const dois = []
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        dois.push(this.selectedDOIs[i].doi)
      }
      const issueFileNames = []
      for (let i = 0; i < this.selectedIssueFiles.length; i++) {
        issueFileNames.push(this.selectedIssueFiles[i].file)
      }
      //console.log('staging ddd issueFileNames', issueFileNames, 'dois', dois)
      publicationApi.stageOnWebHost(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, dois, issueFileNames, notesAndNotification, this, null)
    },
    backFromStageOnWebHost (serverData) {
      this.selectedDOIs = []
      this.selectedIssueFiles = []
      if (serverData.error) {
        console.log('backFromStageOnWebHost with error', serverData.error)
        const message = 'A problem occured during the intitial setup of the tranmission.  Please contact your administrator.'
        this.showProblemWithStagingPreparation(message)
      } else {
        this.showStagingSummaryAndPromptToTrackStatus()
      } 
    }, 

    showStagingSummaryAndPromptToTrackStatus () {
      const message = 'Staging for selected items has commenced. Checking status on items In Transit can be done from the Selection Operations menu.'
      //this.$bvModal.msgBoxConfirm(message,..
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Staging in Progress',
          size: 'sm',
          okVariant: 'primary',
          modalClass: 'modal-primary',
          okTitle: 'Ok',
        })
        .then(value => {
          if (value) {
            this.getStagedOnWebHost()
          }
        })
    },

    showProblemWithStagingPreparation (message) {
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Problem with Transmission',
          size: 'sm',
          okVariant: 'danger',
          modalClass: 'modal-danger',
          okTitle: 'Ok',
        })
    }, 

    promptForMarkAsPublished () {
      //.$bvModal.show(.. will reveal template
      this.$bvModal.show('prompt-for-mark-as-published')
      
      //this.$bvModal.msgBox...  is a convenience where a separate template is not required.
      //commented out here for reference in other solutions that do not require a template
      //this.$bvModal.msgBoxOk(message,..
      /* 
      const message = 'Performing this operation will move all selected files out of production and into the issue archive. Are you sure you want to proceed?'
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Mark Selected as Published',
          size: 'sm',
          okVariant: 'warning',
          modalClass: 'modal-warning',
          okTitle: 'Accept',
          cancelTitle: 'Cancel',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.moveToPublished()
          }
        })
      */
    },
    moveToPublished () {
      let additionalNotes = this.$refs.notesAndNotify.additionalNotes
      if (additionalNotes !== null) {
        console.log('additionalNotes', additionalNotes)
        const tmp = additionalNotes.trim().replace(/\s+/g, '')
        if (tmp === '' || tmp === '<p></p>') {
          additionalNotes = null
        } 
      }

      //console.log('additionalNotes final ', additionalNotes)
      //console.log('uuuu', this.$refs.notesAndNotify.usersToNotify)
      const notesAndNotification = {
        additionalNotes,
        usersToNotify: this.$refs.notesAndNotify.usersToNotify
      }

      const dois = []
      for (let i = 0; i < this.selectedDOIs.length; i++) {
        dois.push(this.selectedDOIs[i].doi)
      }
      const issueFileNames = []
      for (let i = 0; i < this.selectedIssueFiles.length; i++) {
        issueFileNames.push(this.selectedIssueFiles[i].file)
      }
      //console.log('staging ddd issueFileNames', issueFileNames, 'dois', dois)
      publicationApi.moveToPublished(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, dois, issueFileNames, notesAndNotification, this, null)
      
    },
    backFromMoveToPublished (serverData) {
      this.selectedDOIs = []
      this.selectedIssueFiles = []
      if (serverData.error) {
        console.log('backFromMoveToPublished with error', serverData.error)
        const message = 'A problem occured during the intitial setup of the tranmission.  Please contact your administrator.'
        this.showProblemWithMarkAsPublishedPreparation(message)
      } else {
        this.promptToMoveToPublishedIssue()
      } 
    }, 

    promptToMoveToPublishedIssue () {

      const message = 'Publishing has commenced for your selections. You will now be redirected to the publihsed issue for review.  Note that it may take some time for all files to be copied over from production.'
      //this.$bvModal.msgBoxConfirm(message,..
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Mark as published in Progress',
          size: 'sm',
          okVariant: 'primary',
          modalClass: 'modal-primary',
          okTitle: 'Ok',
        })
        .then(value => {
          if (value) {
            this.$router.push({ name: 'publication-issue-admin', params: { issn: this.$route.params.issn, vol: this.$route.params.vol, issue: this.$route.params.issue } })
          }
        })
    },

    showProblemWithMarkAsPublishedPreparation (message) {
      this.$bvModal
        .msgBoxOk(message, {
          title: 'Problem with Transmission',
          size: 'sm',
          okVariant: 'danger',
          modalClass: 'modal-danger',
          okTitle: 'Ok',
        })
    },
  },
}
</script>
