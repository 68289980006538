<template>
  <div v-if="tableOfContents && filterOptions">
    <b-row>
      <b-col cols="2"> <b>Category Filter</b></b-col>
      <b-col cols="6"> <b-form-select v-model="selectedFilter" :options="filterOptions" size="sm"/></b-col>
    </b-row>
    <br>
    <div v-for="(item, index) in tableOfContents" :key="index">
      <b-card>
        <span v-if="item.levelOneLabel && item.levelTwoLabel">
          <b-row v-if="item.levelOneLabel !== 'suppress'">
            <b-col cols="12"> 
              <h3> <b>{{ item.levelOneLabel }}</b></h3>
            </b-col>
          </b-row>
          <br>
          <b-row>
            <b-col cols="1"/> 
            <b-col cols="11"> 
              <h4 class="text-primary"> {{ item.levelTwoLabel }} </h4>
            </b-col>
          </b-row>
          <br>
          <span v-for="(doiRec, index2) in item.record.dois" :key="index2">
            <b-row>
              <b-col cols="1"/> 
              <b-col cols="11"> 
                <h4 class="text-primary"><b>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <router-link :to="articleDetailRouterName(doiRec.doi)"><strong> <span v-html="articleTitle(doiRec.doi)"></span>  </strong></router-link>
                </b></h4>
                {{ doiRec.e_pub_date }}
              </b-col>
            </b-row>
            <b-row v-if="index2 < item.record.dois.length -1">
              <b-col cols="1"/> 
              <b-col cols="11"><hr></b-col>
            </b-row>
          </span>
        </span>
        <span v-else>
          <b-row>
            <b-col cols="12"> 
              <h4 class="text-primary"> {{ item.levelTwoLabel }} </h4>
            </b-col>
          </b-row>
          <br>
          <span v-for="(doiRec, index2) in item.record.dois" :key="index2">
            <b-row>
              <b-col cols="12">
                <h4 class="text-primary"><b>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <router-link :to="articleDetailRouterName(doiRec.doi)"><strong> <span v-html="articleTitle(doiRec.doi)"></span>  </strong></router-link>
                </b></h4>
                {{ doiRec.e_pub_date }}
              </b-col>
            </b-row>
            <b-row v-if="index2 < item.record.dois.length -1">
              <b-col cols="12"> <hr></b-col>
            </b-row>
          </span>
        </span>
      </b-card>
    </div>
  </div>
</template> 
  
<script>

import {
  BCard, BRow, BCol,  BFormSelect,
} from 'bootstrap-vue'

export default {

  components: {
    BCard,
    BRow,
    BCol, 
    BFormSelect,
  },
  props: {
    articles: {
      type: Array,
      default: null
    },
    toc: {
      type: Array,
      default: null
    },
  },
  data () {
    return {
      doiDict: null,
      filterOptions: null,
      tableOfContents: null,
      selectedFilter: null,
    }
  },
  computed: {  
    inProduction () {
      return this.$route.path.includes('production-admin')
    },  
  }, 
  watch: {
    selectedFilter () {
      this.refreshToc()
    },

  },
  mounted () {
    this.initializeFilters(this.refreshToc())
  },
  methods: {
    articleDetailRouterName (doi) {
      const article = this.doiDict[doi]
      //console.log('dddddddddd', article)
      const xmlFileName = article.file_name.split('.xml')[0]
      const returnDict = {
        params: { 
          issn: this.$route.params.issn,
          file_name: xmlFileName,
        } 
      } 
      if (this.$route.path.includes('production-admin')) {
        returnDict.name = 'article-production-detail-admin'
        returnDict.params.vol = this.$route.params.vol
        returnDict.issue = this.$route.params.issue
      } else {
        returnDict.name = 'article-publication-detail-admin'
        returnDict.params.vol = this.$route.params.vol
        returnDict.issue = this.$route.params.issue
      }
      return returnDict
    },
    articleTitle (doi) {
      const article = this.doiDict[doi]
      if (article.title.length === 0) {
        return 'TITLE NOT FOUND'
      }
      //will render text as html, therefore sanitize first
      
      return this.$sanitize(article.title)
    },

    initializeFilters (tocItems) {
 
      const dupCheck = []
      const labels = []
      
      for (let i = 0; i < tocItems.length; i++) {
        //console.log('sssssssssss', tocItems[i])
        let label = tocItems[i].record.tocDivisionsLabel
        if (label && !dupCheck.includes(label)) {
          labels.push({ value: label, text: label })
          dupCheck.push(label)
        }
        let division = '' 
        if (label) {
          division = division.concat(label, ': ')
        }

        label = tocItems[i].record.tocLabel
        if (label && !dupCheck.includes(label)) {
          labels.push({ value: label, text: division.concat(label) })
          dupCheck.push(division.concat(label))
        }
      }
      labels.sort((a, b) => {
        if (a.text < b.text) {
          return -1
        }
        if (a.text > b.text) {
          return 1
        }
        return 0
      })
      this.filterOptions = [{
        value: null,
        text: 'Select an Article Category'
      }]
      for (let i = 0; i < labels.length; i++) {
        this.filterOptions.push({ value: labels[i].value, text: labels[i].text })
      }   

    },
    refreshToc () {
      this.doiDict = {} 
      this.catDict = {}
      for (let i = 0; i < this.articles.length; i++) {
        this.doiDict[this.articles[i].doi] = this.articles[i]
      }

      //console.log('xxxxxxxyyyyyyy', this.toc)
      const tocItems = []
      let priorLevelOneLabel = null
      //let priorLevelTwoLabel = null
      for (let i = 0; i < this.toc.length; i++) {
        const r =  this.toc[i]
        //console.log('taxUriddddd', r.taxUri, 'tocDivisionsLabel', r.tocDivisionsLabel, 'tocLabel', r.tocLabel, 'tagUri', r.tagUri,)
        //console.log(Object.keys(this.toc[i]))

        if (!this.selectedFilter || (this.selectedFilter ===  r.tocDivisionsLabel || this.selectedFilter ===  r.tocLabel)) {
          let record = null
          if (i === 0) {
            record = { levelOneLabel: r.tocDivisionsLabel, levelTwoLabel: r.tocLabel, record: r }
            tocItems.push(record)
            priorLevelOneLabel = r.tocDivisionsLabel
            //priorLevelTwoLabel = r.tocLabel
            continue
          }
          if (r.tocDivisionsLabel && (r.tocDivisionsLabel === priorLevelOneLabel)) {
            record = { levelOneLabel: 'suppress', levelTwoLabel: r.tocLabel, record: r }
            tocItems.push(record)
            priorLevelOneLabel = r.tocDivisionsLabel
            //priorLevelTwoLabel = r.tocLabel
          } else {
            record = { levelOneLabel: r.tocDivisionsLabel, levelTwoLabel: r.tocLabel, record: r }
            tocItems.push(record)
            priorLevelOneLabel = r.tocDivisionsLabel
            //priorLevelTwoLabel = r.tocLabel
          }
        }
      }
      this.tableOfContents = tocItems
      
      return tocItems

    },
  },
}
</script>
