<template>
  <div>
    <b-tabs v-model="tabIndex">
      <b-tab v-if="format == 'literatum'" title="Table of Contents" lazy>
        <br>
        <issue-toc  
          :articles="articles"
          :toc="toc"
          @RefreshDataRequested="refreshData"
        />
      </b-tab>
      <b-tab> 
        <template #title>
          <span class="pill_gap">Files</span>
          <b-badge pill variant="light-secondary">{{ issueFiles.length.toLocaleString() }}</b-badge>
        </template>
        <b-row v-if="$route.params.vol !== '0'"> 
          <b-col cols="9"/> 
          <b-col cols="3"> 
            <b-button 
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary" 
              @click="$bvModal.show('uploadModalId')"
            >
              <feather-icon
                icon="UploadCloudIcon"
                class="mr-50"
              /> 
              Upload Files
            </b-button>
          </b-col>
        </b-row>
        <br>
        <b-card>
          <b-row>
            <b-col v-for="(file,index) in issueFiles" :key="index" cols="6">
              <a class="text-primary" @click="downloadFile('issueFiles', file)">{{ file }}</a>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
      <b-tab title="History" lazy> 
        <div class="demo-inline-spacing">
          <b-form-checkbox v-model="showNotes">
            <b>Show Notes</b>
          </b-form-checkbox>
          <b-form-checkbox v-model="showUpdateActivity">
            <b>Show Update Activity</b>
          </b-form-checkbox>
          <b-form-checkbox v-model="showPublishingActivity">
            <b>Show Publishing Activity</b>
          </b-form-checkbox>
        </div>
        <br>
        <b-card>
          <app-timeline>
            <app-timeline-item 
              v-for="(item, index) in filteredHistory"  :key="index"
              :icon="iconForHistoryItem(item)"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h6>{{ textForHistoryItem(item) }}</h6>
                <small class="text-muted">{{ item.timestamp | formatISODate }}</small>
              </div>
              <div v-if="item.user">{{ item.user.first_name }} {{ item.user.last_name }}</div>
              <div v-if="item.type === 'notes'">
                <b-card bg-variant="light"> 
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <div v-html="$sanitize(item.notes)"></div>
                </b-card> 
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-card>
      </b-tab>
    </b-tabs>
    <!-- modals here-->
    <!-- modal for article upload-->
    <b-modal
      id="uploadModalId" 
      centered
      ok-only
      ok-title="Close"
      title="Upload Issue Files" 
      size="lg"
    >
      <b-form-file 
        v-model="multipleFilesToUpload"
        multiple
        placeholder="Choose a file or drop it here..."
        drop-placeholder="OK to Drop"
        size="lg"
      />
      <br><br>
      <span v-if="multipleFilesToUpload.length > 0">
        <notes-and-notify ref="notesAndNotify" />
        <br><br>
        <b-button 
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          variant="primary" 
          @click.stop="uploadFileRequest()"
        >
          <feather-icon
            icon="UploadCloudIcon"
            class="mr-50"
          /> 
          Upload
        </b-button>`
      </span>
    </b-modal>
    <b-toast
      id="upload-complete-toast"
      auto-hide-delay="1000"
    >
      <b class="text-success"> Upload Finished</b>
    </b-toast>
  </div>
</template>
 
<script>

import fileMgrApi from '@/dl_pubmill/apis/fileMgr'
import publicationApi from '@/dl_pubmill/apis/publication'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import Ripple from 'vue-ripple-directive'
import {
  BTabs, BTab, BCard, BRow, BCol, BButton, BModal, BFormFile, BToast, BFormCheckbox, BBadge
} from 'bootstrap-vue'

import NotesAndNotify from './NotesAndNotify.vue'
import IssueToc from './IssueToc.vue'

export default {
 
  components: {
    BTabs, 
    BTab,
    BCard,
    BRow,
    BCol,
    BButton, 
    BModal, 
    BFormFile, 
    BToast,
    BFormCheckbox,
    BBadge,
    NotesAndNotify,
    AppTimeline,
    AppTimelineItem,
    IssueToc,
  },
  directives: {
    Ripple,
  },
  props: {
    format: {
      type: String,
      default: null
    },  
    issueFiles: {
      type: Array,
      default: null
    },
    articles: {
      type: Array,
      default: null
    },
    toc: {
      type: Array,
      default: null
    },
    audit: {
      type: Object,
      default: null
    }, 
  },
  data () {
    return {
      tabIndex: 0,
      multipleFilesToUpload: [],
      showUpdateActivity: true,
      showPublishingActivity: true,
      showNotes: true,
      allHistory: null,
      filteredHistory: null,
    }
  },
  computed: {
    inProduction () {
      return this.$route.path.includes('production-admin')
    },
  },
  watch: {
    showUpdateActivity () {
      this.filterTimeline()
    },
    showPublishingActivity () {
      this.filterTimeline()
    },
    showNotes () {
      this.filterTimeline()
    },
  },
  mounted () {
    console.log('1111 Issue files History tab mounted', this.$route)
    this.buildHistoryFromAudit()
  },
  methods: {
    refreshData () {
      this.$emit('RefreshDataRequested')
    },
    iconForHistoryItem (item) {
      if (item.type === 'notes') {
        return 'FileTextIcon'
      }
      if (item.type === 'staging') {
        if (item.status === 'Reopened in Production') {
          return 'LayoutIcon'
        } 
        if (item.status === 'Moved To Published') {
          return 'ArchiveIcon'
        } 
        if (item.status === 'in staging') {
          return 'ExternalLinkIcon'
        } 
        if (item.status === 'in transit') {
          return 'ExternalLinkIcon'
        } 
        return 'CircleIcon'
      }
      if (item.changeActivityName === 'File Uploaded') {
        return 'UploadCloudIcon'
      } 
      return 'CircleIcon'
    },
    textForHistoryItem (item) {
      if (item.type === 'notes') {
        return 'Note added'
      }
      if (item.type === 'staging') {
        if (item.status === 'Reopened in Production') {
          return item.status
        } 
        if (item.status === 'Moved To Published') {
          return item.status
        } 
        if (item.status === 'in staging') {
          return 'Staged on Web Host'
        } 
        if (item.status === 'in transit') {
          return 'Staging Commenced'
        }
        return item.status
      }
      return item.changeActivityName
    },

    buildHistoryFromAudit () {
      const tmp = []
      if (this.audit && this.audit.changes) {
        for (let i = 0; i < this.audit.changes.length; i++) {
          const rec = this.audit.changes[i]
          rec.type = 'changes'
          tmp.push(rec)
        }
      }
      if (this.audit && this.audit.staging) {
        for (let i = 0; i < this.audit.staging.length; i++) {
          const rec = this.audit.staging[i]
          rec.type = 'staging'
          tmp.push(rec)
        }
      }
      if (this.audit && this.audit.notes) {
        for (let i = 0; i < this.audit.notes.length; i++) {
          const rec = this.audit.notes[i]
          rec.type = 'notes'
          tmp.push(rec)
        }
      }

      tmp.sort((a, b) => {
        if (a.timestamp > b.timestamp) {
          return -1
        }
        if (a.timestamp < b.timestamp) {
          return 1
        }
        return 0
      })
      //console.log('aaaaaaa', tmp)

      this.allHistory = tmp
      this.filterTimeline()
    },
    filterTimeline () {
      console.log('in filterTimeline')
      const tmp = []
      for (let i = 0; i < this.allHistory.length; i++) {
        const rec = this.allHistory[i]
        if (rec.type === 'notes' && this.showNotes) {
          tmp.push(rec)
        } else if (rec.type === 'staging' && this.showPublishingActivity) {
          tmp.push(rec)
        } else if (rec.type === 'changes' && this.showUpdateActivity) {
          tmp.push(rec)
        }
        //console.log(rec.type, this.showNotes, this.showPublishingActivity, this.showUpdateActivity) 
      }
      this.filteredHistory = tmp
    },
    downloadFile (folder, file) {
      this.$emit('DownloadRequested', '', folder, file)
    }, 
    updateAudit (changeActivityName) {
      console.log('changeActivityName', changeActivityName)
      
      let additionalNotes = this.$refs.notesAndNotify.additionalNotes
      if (additionalNotes !== null) {
        console.log('additionalNotes', additionalNotes)
        const tmp = additionalNotes.trim().replace(/\s+/g, '')
        if (tmp === '' || tmp === '<p></p>') {
          additionalNotes = null
        } 
      }
    
      //console.log('additionalNotes final ', additionalNotes)
      //console.log('uuuu', this.$refs.notesAndNotify.usersToNotify)
      const notesAndNotification = {
        additionalNotes,
        usersToNotify: this.$refs.notesAndNotify.usersToNotify
      }

      const issueFileNames = []
      for (let i = 0; i < this.multipleFilesToUpload.length; i++) {
        issueFileNames.push(this.multipleFilesToUpload[i].name)
      }
      
      //console.log('staging ddd issueFileNames', issueFileNames)
      publicationApi.updateAudit(this.$route.params.issn, this.$route.params.vol, this.$route.params.issue, [], issueFileNames, changeActivityName, notesAndNotification, this, null)
      
    },
    backFromUpdateAudit (serverData) {
      if (serverData.error) {
        console.log('backFromUpdateAudit with error', serverData.error)
      }
      console.log('backFromUpdateAudit success')  
      this.refreshData()
    }, 
    
    uploadFileRequest () {
      
      if (!(this.inProduction)) {
        console.log('error.  needs to be production')
        return
      }
 
      const filesToUpload = this.multipleFilesToUpload
    
      //console.log('this.filesToUpload', filesToUpload.length, filesToUpload)
      for (let i = 0; i < filesToUpload.length; i++) {
        const queryParams = {
          issn: this.$route.params.issn,
          id_folder: 'issue-files',
          rawFile: filesToUpload[i],
          file_status: 'production',
          volume: this.$route.params.vol,
          issue: this.$route.params.issue,
        } 
        //let file = this.fileName.trim()
        //file = file.replace(/\s/g, '_')
        fileMgrApi.uploadFileRequest(queryParams, this, null) 
      }
    },
    backFromUploadFileRequest (serverData) {
      console.log('backFromUploadFileRequest', serverData)
      if (serverData.error) {
        console.log('serverData.error', serverData.error)
        this.$bvModal
          .msgBoxOk(serverData.error, {
            title: 'Sorry. There was a problem with the upload',
            size: 'sm',
            okVariant: 'danger',
            modalClass: 'modal-danger',
            okTitle: 'Ok',
          })
      } else { 
        this.refreshData()
        this.$bvToast.show('upload-complete-toast')
        if (this.multipleFilesToUpload.length > 1) {
          this.updateAudit('Issue Files Upload')
        } else {
          this.updateAudit('Issue File Upload')
        }
        this.multipleFilesToUpload = []
      }
    },
  }
}
</script>
<style lang="scss">
  .pill_gap {
      margin-right:10px;
  }
</style>
