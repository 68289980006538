<template>
  <div>
    <br>
    <h3 style="margin-left:2rem;">Filters</h3>
    <b-card bg-variant="light">
      <span v-if="tab === 2">
        <b-list-group>
          <b-list-group-item class="bg-transparent">
            <b-form-checkbox v-model="showUnstaged" class="custom-control-primary" switch inline>
              <b>Show Unstaged</b>
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item class="bg-transparent">
            <b-form-checkbox v-model="showInTransit" class="custom-control-primary" switch inline>
              <b>Show In Transit</b>
            </b-form-checkbox>
          </b-list-group-item>
          <b-list-group-item class="bg-transparent">
            <b-form-checkbox v-model="showInStaging" class="custom-control-primary" switch inline>
              <b>Show In Staging</b>
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>
        <br>
      </span>
      <article-filters-sidebar
        @ArticleFilteringRequested="applyArticleFiltering"
      />
    </b-card>
  </div>
</template>
   
<script>

import {
  BCard, BListGroup, BListGroupItem, BFormCheckbox, 
} from 'bootstrap-vue'

import ArticleFiltersSidebar from '../issue/ArticleFiltersSidebar.vue'

export default {
  components: {
    BCard,
    BListGroup, 
    BListGroupItem, 
    BFormCheckbox, 
    ArticleFiltersSidebar,
  },
  props: {
    tab: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      issueFileToUpload: null,
      showUnstaged: true,
      showInTransit: true,
      showInStaging: true,
    }
  }, 
  computed: {
  },
  watch: {
    showUnstaged () {
      this.$emit('StageFilteringRequested', this.showUnstaged, this.showInTransit, this.showInStaging)
    }, 
    showInTransit () {
      this.$emit('StageFilteringRequested', this.showUnstaged, this.showInTransit, this.showInStaging)
    },  
    showInStaging () {
      this.$emit('StageFilteringRequested', this.showUnstaged, this.showInTransit, this.showInStaging)
    },  

  },  
  methods: { 
    applyArticleFiltering (showValid, showErrors, showWarnings, showResearchArticles) { 
      this.$emit('ArticleFilteringRequested', showValid, showErrors, showWarnings, showResearchArticles)
    },
  },    

}
</script>
