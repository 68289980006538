<template>
  <div>
    <b-list-group>
      <b-list-group-item class="bg-transparent">
        <b-form-checkbox v-model="showResearchArticles" class="custom-control-primary" switch inline>
          <b>Research Articles</b>
        </b-form-checkbox>
      </b-list-group-item>
    </b-list-group>
    <br>
    <b-list-group>
      <b-list-group-item class="bg-transparent">
        <b-form-checkbox v-model="showValid" class="custom-control-success" switch inline>
          <b class="text-success">Valid Articles</b>
        </b-form-checkbox>
      </b-list-group-item>
      <b-list-group-item class="bg-transparent"> 
        <b-form-checkbox v-model="showErrors" class="custom-control-danger" switch inline>
          <b class="text-danger">Articles with Errors</b>
        </b-form-checkbox>
      </b-list-group-item>
      <b-list-group-item class="bg-transparent">
        <b-form-checkbox v-model="showWarnings" class="custom-control-warning" switch inline>
          <b class="text-warning">Articles with Warnings</b>
        </b-form-checkbox>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
   
<script>

import {
  BListGroup, BListGroupItem, BFormCheckbox, 
} from 'bootstrap-vue'

export default {
  components: {
    BListGroup, 
    BListGroupItem, 
    BFormCheckbox,
  },
  props: {
  },
  data() {
    return {
      showResearchArticles: true,
      showValid: true,
      showErrors: true,
      showWarnings: true,
    }
  }, 
  computed: { 
  },
  watch: {
    showResearchArticles () { 
      this.$emit('ArticleFilteringRequested', this.showValid, this.showErrors, this.showWarnings, this.showResearchArticles)
    }, 
    showValid () { 
      this.$emit('ArticleFilteringRequested', this.showValid, this.showErrors, this.showWarnings, this.showResearchArticles)
    }, 
    showErrors () {
      this.$emit('ArticleFilteringRequested', this.showValid, this.showErrors, this.showWarnings, this.showResearchArticles)
    },
    showWarnings () {
      this.$emit('ArticleFilteringRequested', this.showValid, this.showErrors, this.showWarnings, this.showResearchArticles)
    },
  }, 
  methods: { 
    
  },
}
</script>
