<template>
  <div v-if="articles">
    <b-row>
      <b-col cols="8">
        <span v-if="inProduction">
          <h3 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
            Articles in Production 
          </h3>
          <h5 style="margin-top:.3rem;"> 
            Volume: {{ $route.params.vol }} - Issue: {{ $route.params.issue }} 
          </h5>
        </span>
        <span v-else>
          <h3 class="content-header-title float-left pr-1 mb-0" style="margin-right:1rem;"> 
            Published Issue
          </h3>
          <h5 style="margin-top:.3rem;"> 
            <router-link :to="archiveVolRouterName">Volume: {{ $route.params.vol }}</router-link>
            / Issue: {{ $route.params.issue }}
          </h5>
        </span>
      </b-col>
      <b-col cols="3"><b>{{ pubDateDisplay }}</b></b-col>
      <b-col v-if="activeTabName !== 'Issue'"  cols="1">
        <b-button 
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          v-b-toggle.sidebar-right
          size="sm"
          variant="gradient-primary"
        >
          <feather-icon
            icon="MenuIcon"
          /> 
        </b-button>
        <b-sidebar 
          id="sidebar-right" 
          bg-variant="white"
          right
          backdrop
          shadow
        >
          <production-issue-sidebar
            v-if="inProduction && activeTabName !== 'Crossref'"
            :tab="tabIndex"
            @ArticleFilteringRequested="applyArticleFiltering"
            @StageFilteringRequested="applyStageFiltering"
          /> 
          <published-issue-sidebar 
            v-if="publishedIssue && activeTabName !== 'Crossref'"
            @ArticleFilteringRequested="applyArticleFiltering"
          /> 
          <deposits-sidebar
            v-if="activeTabName === 'Crossref'"
          />
    
        </b-sidebar>
      </b-col>
    </b-row> 
    <br>
    <b-card bg-variant="light">
      <b-tabs  v-model="tabIndex" :active-nav-item-class="tabClass">
        <b-tab>
          <template #title>
            <span class="pill_gap">Articles</span>
            <b-badge pill variant="light-secondary">{{ unfilteredArticles.length.toLocaleString() }}</b-badge>
          </template>
          <span v-if="waitingOnFullLoad"> 
            <b-button
              variant="warning"
              disabled
              class="mr-4"
            >
              <b-spinner
                small
                type="grow"
              />
              Validation Running...
            </b-button>
            <br>
          </span>
          <b-row v-if="totalErrors > 0 || totalWarnings > 0">
            <b-col cols="8">
              <b-row>
                <b-col cols="3">
                  <nobr><b>{{ totalValid }} Valid</b></nobr>
                </b-col> 

                <b-col cols="3">
                  <nobr><b class="text-danger">{{ totalErrors }} Errors </b></nobr>
                </b-col>

                <b-col cols="3">
                  <nobr><b class="text-warning">{{ totalWarnings }} Warnings</b></nobr>
                </b-col> 
                <b-col cols="3"/>
              </b-row>
            </b-col>    
          </b-row>

          <br>
          <div v-for="(article, index) in articles" :key="index">
            <b-card> 
              <article-resources  
                :format="format"
                :article="article" 
                @DownloadRequested="downloadFile"
              />
            </b-card>
          </div>
        </b-tab>
        <b-tab v-if="!waitingOnFullLoad">
          <template #title>
            <span>Issue</span>
          </template>
          <br>
          <issue-manager 
            v-if="issueFiles" 
            :format="format"
            :issue-files="issueFiles" 
            :articles="unfilteredArticles"
            :toc="toc"
            :audit="issueFileAudit" 
            @DownloadRequested="downloadFile"
            @RefreshDataRequested="refreshData"
          />
        </b-tab>
        <b-tab v-if="inProduction" lazy>
          <template #title>
            <span>Curation</span>
          </template>
          <br>
          <issue-curation-production 
            :articles="articles" 
            :issue-files="issueFiles" 
            :show-unstaged="showUnstaged" 
            :show-in-transit="showInTransit"
            :show-in-staging="showInStaging"
          />
        </b-tab>
        <b-tab v-if="publishedIssue && !waitingOnFullLoad" lazy>
          <template #title>
            <span>Curation</span>
          </template>
          <br>
          <issue-curation-publication
            :articles="articles" 
            :issue-files="issueFiles"
          />
        </b-tab>
        <b-tab v-if="!waitingOnFullLoad" lazy>
          <template #title>
            <span>Crossref</span>
          </template>
          <br>
          <deposits-publication
            :articles="articles" 
          />
        </b-tab>
        <!-- Must always be last tab - see explaination above activeTabName()-->
        <b-tab v-if="unresolvedFiles">
          <template #title>
            <span class="pill_gap">Unresolved</span>
            <b-badge pill variant="danger">{{ unresolvedFilesCount.toLocaleString() }}</b-badge>
          </template>
          <br>
          <b-card>
            <b-row>
              <b-col v-for="(file,index) in unresolvedFiles" :key="index" cols="12">
                {{ file }}
              </b-col>
            </b-row>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template> 
  
<script>

import publicationApi from '@/dl_pubmill/apis/publication'
import fileMgrApi from '@/dl_pubmill/apis/fileMgr'
import {
  BCard, BRow, BCol, BButton, BTabs, BTab, BBadge, BSidebar, VBToggle, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProductionIssueSidebar from '../production/ProductionIssueSidebar.vue'
import PublishedIssueSidebar from './PublishedIssueSidebar.vue'
import DepositsSidebar from './DepositsSidebar.vue'

import ArticleResources from './ArticleResources.vue'
import IssueManager from './IssueManager.vue'
import IssueCurationPublication from './IssueCurationPublication.vue'
import IssueCurationProduction from '../production/IssueCurationProduction.vue'
import DepositsPublication from './DepositsPublication.vue'

export default {

  components: {
    ArticleResources,
    IssueManager,
    IssueCurationProduction,
    IssueCurationPublication,
    DepositsPublication,
    BCard,
    BRow,
    BCol, 
    BButton,
    BTabs, 
    BTab,
    BBadge,
    BSidebar,
    ProductionIssueSidebar,
    PublishedIssueSidebar,
    DepositsSidebar,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data () {
    return {
      format: null,
      fullArticleValidationRetrievalPending: null,
      articles: null,
      issueFiles: null,
      issueFileAudit: null,
      unfilteredArticles: null,
      waitingOnFullLoad: null,
      toc: null,
      unresolvedFiles: null,
      filesDict: null,      
      totalValid: 0,
      totalErrors: 0,
      totalWarnings: 0,
      tabIndex: 0,
      showUnstaged: true,
      showInTransit: true,
      showInStaging: true,
    }
  },
  computed: {  
    //note if Unresolved is not last ,  the v-if condition will change the tabIndex order
    //also note the v-show does not work with hiding tabs
    activeTabName() {
      console.log('computed new activeTabName ', this.tabIndex)
      if (this.tabIndex === 0) return 'Articles'
      if (this.tabIndex === 1) return 'Issue'
      if (this.tabIndex === 2) return 'Curation'
      if (this.tabIndex === 3) return 'Crossref'
      if (this.tabIndex === 4) return 'Unresolved'
      
      return 'Unkown Tab Error'
    },
    pubDateDisplay () {
      let returnVal = ''
      if (this.articles.length > 0) {
        returnVal = this.articles[0].p_pub_date
      }
      return returnVal
    },
    inProduction () {
      return this.$route.path.includes('production-admin')
    }, 
    publishedIssue () {
      return this.$route.path.includes('publication-issue-admin') ||  this.$route.path.includes('current-issue-admin')
    }, 
    archiveVolRouterName () {
      return {
        name: 'publication-archive-admin-'.concat(this.$route.params.vol), 
        params: {
          issn: this.$route.params.issn,
          vol: this.$route.params.vol,
        }
      }
    },
    articleFilesCount() {
      let tally = 0
      Object.keys(this.filesDict).forEach(key => {
        tally += this.filesDict[key].length
      })
      return tally
    },
    articleStagedCount() {
      let tally = 0
      for (let i = 0; i < this.unfilteredArticles.length; i++) {
        if (this.unfilteredArticles[i].staged) {
          tally += 1
        }
      }
      return tally
    },
    unresolvedFilesCount() {
      if (this.unresolvedFiles) {
        return this.unresolvedFiles.length
      }
      return 0
    },
    tabClass () {
      if (this.unresolvedFiles) {
        return 'text-danger'
      }
      return 'text-primary'
    }
  },
  watch: {
    $route: {
      //watch on route changes it not only needed when same .vue is entered from multiple nav points 
      handler (newVal, oldVal) {
        console.log('1111 PublicationIssueAdmin route:', this.$route.name, 'newVal:', newVal.name, 'oldVal:', oldVal)
        if (this.$route.params.tab) {
          this.tabIndex = parseInt(this.$route.params.tab, 10)
        } else {
          this.tabIndex = 0
        }
        this.refreshData()
      }
    },
  },
  mounted () {
    console.log('1111 PublicationIssueAdmin mounted', this.$route)
    this.refreshData()
  },
  methods: {
    resetTab(tab) {
      console.log('4444444444 setting new tabIndex', tab)
      this.tabIndex = parseInt(tab, 10)
    },
    downloadFile (doi, folder, file) {
      this.getDownloadLink(doi, folder, file)
    },

    getDownloadLink (doi, folder, file) {

      const queryParams = {
        issn: this.$route.params.issn,
        doi,
        content_type: folder,
        file_name: file
      }

      if (this.$route.path.includes('production-admin')) {
        queryParams.file_status = 'production'
        queryParams.volume =  this.$route.params.vol
        queryParams.issue = this.$route.params.issue
      } else {
        queryParams.file_status = 'published'
        queryParams.volume =  this.$route.params.vol
        queryParams.issue = this.$route.params.issue
      }

      fileMgrApi.getDownloadLink(queryParams, this, null)
    },

    backFromGetDownloadLink (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData.url
        const fileName = serverData.name
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        //remove the following if you want the  browser to attempt to render instead of a download
        fileLink.download = fileName
        fileLink.target = '_blank'
        document.body.appendChild(fileLink)
        //console.log('dfff', fileLink, fileName)
        fileLink.click() 
        document.body.removeChild(fileLink)
      } 
    },
    file_prefix (article) {
      if (article.page) {
        return article.page.split('-')[0]
      }
      const nodes = article.doi.split('/')
      let temp =  article.doi.split(nodes[0].concat('/'))[1]

      temp = temp.split('.').join('')
      temp = temp.split('-').join('')
      if (temp.length > 8) {
        temp = temp.substring(temp.length - 7, temp.length)
      }
      return temp
 
    },
  
    publicationIssueData () {
      return this.$store.state.pubmillGlobalStore.currentPublicationObject.issueData
    },

    applyStageFiltering (showUnstaged, showInTransit, showInStaging) {
      this.showUnstaged = showUnstaged
      this.showInTransit = showInTransit
      this.showInStaging = showInStaging
    },

    applyArticleFiltering (showValid, showErrors, showWarnings, showResearchArticles) {
      this.totalValid = 0
      this.totalErrors = 0
      this.totalWarnings = 0
      this.filesDict = { 
        xml: [], pdf: [], supp: [], img_names: [], text_img_names: [], lg_img: [], med_img: [], sm_img: []
      }
      const filteredArticles = []
      for (let i = 0; i < this.unfilteredArticles.length; i++) {
        let filterRecord = false
        const article = this.unfilteredArticles[i]
    
        if (Object.keys(article.errors).length > 0) {
          this.totalErrors += 1
          if (!showErrors) {
            filterRecord = true
          }
        } else if (Object.keys(article.warnings).length > 0) {
          this.totalWarnings += 1
          if (!showWarnings) {
            filterRecord = true
          }
        } else {
          this.totalValid += 1
          if (!showValid) {
            filterRecord = true
          }
        }
        if (article.article_type === 'research-article'  && !showResearchArticles) {
          filterRecord = true
        }

        if (filterRecord) {
          continue
        }
  
        filteredArticles.push(article)

        this.filesDict.xml.push(article.file_name)
        this.filesDict.pdf.push(article.pdf_name)
      
        this.filesDict.supp = this.filesDict.supp.concat(article.supplementary_names)

        this.filesDict.img_names = this.filesDict.img_names.concat(article.img_names)
        this.filesDict.text_img_names = this.filesDict.text_img_names.concat(article.text_img_names)
        this.filesDict.lg_img = this.filesDict.lg_img.concat(article.large_images_names)
        this.filesDict.med_img = this.filesDict.med_img.concat(article.medium_images_names)
        this.filesDict.sm_img = this.filesDict.sm_img.concat(article.small_images_names)

      }

      this.articles = filteredArticles

    },

    ///if  queryParams.file_status = 'published' and issn ==  '1091-6490'
    searchQueryRequest () {
      this.waitingOnFullLoad = true
      this.unfilteredArticles = null
      const requestObj = {
        issn: this.$route.params.issn,
        searchOnIssn: this.$route.params.issn,
      }
      requestObj.volume =  this.$route.params.vol
      requestObj.issue =   this.$route.params.issue
      requestObj.returnRows =  300
      requestObj.action =  'search_query_articles_request'
      publicationApi.searchQueryRequest(requestObj, this, null)
    },
    backFromSearchQueryRequest (serverData) {
      if (serverData.error) {
        console.log(serverData.error)
        this.getPublicationAdminIssue()  
      } else {
        const pageResults =  serverData.hits.hits
        //console.log('reportResults', serverData)

        this.format = 'literatum'
        
        const tmpArticles = []
        for (let i = 0; i < pageResults.length; i++) {
          const source = pageResults[i].source
          //console.log('search source', source)
          tmpArticles.push({
            e_pub_date: source.epub.split('T')[0],
            doi: source.doi,
            volume: source.volume,
            issue: source.issue,
            title: source.title,
            article_type: source.article_type,
            pdf_name: pageResults[i].id.concat('.pdf'),
            file_name: pageResults[i].id.concat('.xml'),
          })
          tmpArticles.sort((a, b) => {
            if (a.e_pub_date < b.e_pub_date) {
              return 1
            }
            if (a.e_pub_date > b.e_pub_date) {
              return -1
            }
            if (a.title < b.title) {
              return -1
            }
            if (a.title > b.title) {
              return 1
            }
            return 0
          })

        }
  
        this.unfilteredArticles = tmpArticles
        this.articles = tmpArticles
        this.getPublicationAdminIssue()

      }
    },

    getPublicationAdminIssue () {
      const queryParams = {
        issn: this.$route.params.issn
      }
      if (this.inProduction) { 
        queryParams.file_status = 'production'
        queryParams.volume =  this.$route.params.vol
        queryParams.issue = this.$route.params.issue
      } else {
        queryParams.file_status = 'published'
        queryParams.volume =  this.$route.params.vol
        queryParams.issue = this.$route.params.issue
      }  
      
      // console.log('dddddddddd', queryParams)

      publicationApi.getPublicationAdminIssue(queryParams, this, null)
  
    },

    backFromGetPublicationAdminIssue (serverData) {
      console.log('abbbbbbb1 ', serverData)
      if (serverData.error) {
        console.log(serverData.error)
        return
      } 
      this.format = serverData.format
      this.unresolvedFiles = null
      if (serverData.unresolved_names.length > 0) {
        this.unresolvedFiles = serverData.unresolved_names
      } 
      let tmpArticles = []
      if (serverData.article_summaries.length > 0) {
        tmpArticles = serverData.article_summaries
        /*
        if (tmpArticles[0].page) {
          tmpArticles.sort((a, b) => {
            if (a.page < b.page) {
              return -1
            }
            if (a.page > b.page) {
              return 1
            }
            return 0
          })
        } 
        */
        tmpArticles.sort((a, b) => {
          if (a.e_pub_date < b.e_pub_date) {
            return 1
          }
          if (a.e_pub_date > b.e_pub_date) {
            return -1
          }
          if (a.title < b.title) {
            return -1
          }
          if (a.title > b.title) {
            return 1
          }
          return 0
        })
      }
      this.unfilteredArticles = tmpArticles
      
      this.issueFiles = serverData.peripherals_names
      this.issueFileAudit = serverData.audit
      this.toc = serverData.tableOfContents
      //console.log('kkkkkk', serverData)

      const currentPublicationName = this.$store.state.pubmillGlobalStore.currentPublicationObject.name
      if (!currentPublicationName) {
        this.$store.commit('pubmillGlobalStore/updateCurrentPublicationId', this.$route.params.issn)
      }
      this.waitingOnFullLoad = null
      this.applyArticleFiltering(true, true, true, true)   

      if (this.$route.params.tab) {
        setTimeout(() => this.resetTab(this.$route.params.tab), 500) 
      } 
        
    },
 
    refreshData () {
      if (this.inProduction) {  
        this.getPublicationAdminIssue()
      } else {
        this.searchQueryRequest()
      }
    },
  },
}
</script>
<style lang="scss">
.pill_gap {
    margin-right:10px;
}
</style>
