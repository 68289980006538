<template>
  <section> 
    <b-button
      :id="'popover-'.concat(folder, file)"
      variant="flat-primary"
      style="padding-left:0;padding-top:.5rem;padding-bottom:0;"
    >
      {{ file }}
    </b-button>
    <b-popover
      :target="'popover-'.concat(folder, file)"
      placement="right"
      triggers="hover"
    >
      <template #title>
        Operations
      </template> 
      <b-list-group flush>
        <b-list-group-item class="d-flex">
          <span class="mr-1">
            <feather-icon
              icon="DownloadCloudIcon"
              size="16"
            />
          </span>
          <a class="text-primary" @click="downloadFile(file, folder, article)">Download</a>
        </b-list-group-item>
        <b-list-group-item v-if="inProduction && folder !=='xml'" class="d-flex">
          <span class="mr-1 text-danger">
            <feather-icon
              icon="XCircleIcon"
              size="16"
              color="danger"
            />
          </span>
          <a class="text-danger" @click="removeFile(folder, file)">Remove</a>
        </b-list-group-item>
      </b-list-group>
    </b-popover>
  </section>
</template>
 
<script>

import fileMgrApi from '@/dl_pubmill/apis/fileMgr'

import {
  BPopover, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {

  components: {
    BPopover, 
    BListGroup, 
    BListGroupItem,
    BButton,
  },

  props: {
    format: {
      type: String,
      default: null
    },  
    article: {
      type: Object,
      default: null
    }, 
    folder: {
      type: String,
      default: null
    },  
    file: {
      type: String,
      default: null
    },     
  },
  data () {
    return {

    }
  },
  computed: { 
    inProduction () {
      return this.$route.path.includes('production-admin')
    },
  },
  methods: {
    
    downloadFile (file, folder, article) {
      //console.log('fffffff folder', file, folder, article)
      this.getDownloadLink(file, folder, article)
    },

    getDownloadLink (file, folder, article) {
      const queryParams = {
        issn: this.$route.params.issn,
        content_type: folder,
        file_name: file
      } 

      if (this.inProduction) {
        queryParams.file_status = 'production'
        queryParams.volume =  article.volume
        queryParams.issue = article.issue
        queryParams.id_folder = article.doi
      } else {
        queryParams.file_status = 'published'
        queryParams.volume =  article.volume
        queryParams.issue = article.issue
        queryParams.id_folder = article.doi
      }

      fileMgrApi.getDownloadLink(queryParams, this, null)
    },

    backFromGetDownloadLink (serverData) {
      // console.log('sss', serverData)
      if (serverData.error) {
        console.log(serverData.error) 
      } else {
        //console.log('zzzzzzzzzzzzzxxx', serverData)
        /* do this when server data is the fike blob and not a url
        const fileURL = window.URL.createObjectURL(new Blob([serverData]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'zzz.xml')
        document.body.appendChild(fileLink)
        fileLink.click() 
        */
        //since serverData  is a url  ( one that has a presigned s3 url )
        const fileURL = serverData.url
        const fileName = serverData.name
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        //remove the following if you want the  browser to attempt to render instead of a download
        fileLink.download = fileName
        fileLink.target = '_blank'
        document.body.appendChild(fileLink)
        //console.log('dfff', fileLink, fileName)
        fileLink.click() 
        document.body.removeChild(fileLink)
      } 
    }, 
  }
}
</script>
